<template>
  <div id="app">
    <div class="container" v-if="available.length === 0">
      <!-- Outer Row -->
      <div class="row justify-content-center">

        <div class="col-xl-10 col-lg-12 col-md-9">

          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <!-- Nested Row within Card Body -->
              <div class="row">
                  <div class="p-5">
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-4">Авторизация</h1>
                    </div>
                    <form class="user" @submit.prevent="login">
                      <div class="form-group mb-2">
                        <input type="text" v-model="auth.username" required class="form-control form-control-user" placeholder="Введите имя пользователя">
                      </div>
                      <div class="form-group mb-2">
                        <input type="password" v-model="auth.password" required class="form-control form-control-user" placeholder="Введите пароль">
                      </div>
                      <button type="submit" class="btn btn-primary btn-user btn-block">Войти</button>
                    </form>
                  </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
    <slot v-else>
    <ul class="nav nav-tabs">
      <li class="nav-item" v-if="available.indexOf('first') !== -1">
        <a :class="'nav-link ' + (select === 'first' ? 'active':'')" @click="changeTab('first')">Первая</a>
      </li>
      <li class="nav-item" v-if="available.indexOf('second') !== -1">
        <a :class="'nav-link ' + (select === 'second' ? 'active':'')" @click="changeTab('second')">Вторая</a>
      </li>
      <li class="nav-item" v-if="available.indexOf('plan') !== -1">
        <a :class="'nav-link ' + (select === 'plan' ? 'active':'')" @click="changeTab('plan')">Планирование</a>
      </li>
      <li class="nav-item" v-if="available.indexOf('bs-1') !== -1">
        <a :class="'nav-link ' + (select === 'bs-1' ? 'active':'')" @click="changeTab('bs-1')">БС</a>
      </li>
      <li class="nav-item" v-if="available.indexOf('bs-2') !== -1">
        <a :class="'nav-link ' + (select === 'bs-2' ? 'active':'')" @click="changeTab('bs-2')">БС 2</a>
      </li>
      <li class="nav-item" v-if="available.indexOf('bs-plan') !== -1">
        <a :class="'nav-link ' + (select === 'bs-plan' ? 'active':'')" @click="changeTab('bs-plan')">БС Планирование</a>
      </li>
      <li class="nav-item bg-danger">
        <a class="nav-link text-white" @click="logout">Выйти</a>
      </li>
    </ul>
    <home-view v-show="select === 'first'" :available="available"/>
    <second-home-view v-show="select === 'second'" :available="available"/>
    <plan-view v-show="select === 'plan'"/>
    <presentation-one v-show="select === 'bs-1'"/>
    <presentation-two v-show="select === 'bs-2'"/>
    <plan-two-view v-show="select === 'bs-plan'"/>
    </slot>
  </div>
</template>

<script>

import homeView from "@/HomeView";
import SecondHomeView from "@/SecondHomeView";
import PlanView from "@/PlanView";
import PlanTwoView from "@/PlanTwoView";
import PresentationOne from "@/PresentationOne";
import PresentationTwo from "@/PresentationTwo";
import VueCookie from 'vue-cookie';
import axios from "axios";

export default {
  name: "App",
  components:{PresentationTwo, PresentationOne, SecondHomeView, homeView, PlanView, PlanTwoView},
  data(){
    return{
      auth: {
        username: null,
        password: null
      },
      available: [],
      username: null,
      password: null,
      select: null,
      hostname: null
    }
  },
  methods:{
    logout() {
      this.auth={username:null, password: null}
      this.available=[]
      VueCookie.delete('auth')
    },
    login() {
      var json = JSON.stringify(this.auth)
      axios.post('https://graph-api.ribambelle.ru/site/auth', this.auth).then((res)=>{
        if(res.data.available) this.available = res.data.available
        if(this.available.length > 0) {
          VueCookie.set('auth', json, {expires: '1Y', domain: this.hostname, secure: '', sameSite: 'Lax'})
          this.select = this.available[0]
        } else if(res.data.error) alert(res.data.error)
      })
    },
    changeTab(select){
      this.select=select
    }
  },
  mounted() {
    var hostname = window.location.hostname
    if (hostname !== 'localhost') {
      hostname = hostname.split('.')
      if (hostname.length === 3) this.hostname = hostname[1] + '.' + hostname[2]
      else this.hostname = hostname[0] + '.' + hostname[1]
    } else {
      this.hostname = 'localhost'
    }
    var cookie = VueCookie.get('auth')
    var json = {}
    if(cookie) json = JSON.parse(cookie)
    if(json.username && json.password) {
      this.auth = json
      this.login()
    }
  }
}
</script>

<style>
body,html{
  padding: 0;
  margin: 0;
}
.nav-item{
  cursor: pointer;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
