<template>
  <div>
    <div class="dropdown-list">
      <drop-down-calendar
          @select-deal="optionSelectDate"
      />
      <button @click="loadData">Применить фильтр</button>
    </div>
    <div v-if="Object.keys(table).length">
      <table>
        <thead>
        <tr>
          <th rowspan="2" style="width: 10%">Дата</th>
          <th colspan="2" style="width: 20%" v-for="name in names">{{name}}</th>
        </tr>
        <tr>
          <slot v-for="restaurant in getRestaurants">
            <th style="width: 10%">₽</th><th style="width: 10%">чел</th>
          </slot>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(restaurants, date) in table">
          <td>{{date}}</td>
          <slot v-for="(name, nkey) in names">
<!--          <slot v-for="(array, restaurant) in restaurants">-->
            <slot v-for="(value, key) in restaurants[nkey]">
              <td><input type="number" v-model="table[date][nkey][key]"></td>
            </slot>
          </slot>
        </tr>
        </tbody>
      </table>
      <button style="width: calc(100% - 20px); margin: 10px" @click="savePlan">Сохранить план</button>
    </div>
  </div>
</template>

<script>
import DropDown from "@/Components/dropDown";
import DropDownCalendar from "@/Components/dropDownCalendar";
import GoogleChart from "@/Components/GoogleChart";
import {FunctionalCalendar} from "vue-functional-calendar";
import axios from "axios";
import BarChart from "@/Components/BarChart";
import PieChart from "@/Components/PieChart";
import Chart from 'chart.js/auto'

export default {
  name: "SecondHomeView",
  components: {DropDownCalendar},
  data(){
    return {
      date: null,
      table: {},
      names: {
        'RIBAMBELLE Vremena Goda': 'ВГ | Лок. A',
        'RIBAMBELLE Green': 'Бс | Лок. B',
        'Ribambelle Obolensky': 'ОБ | Лок. C',
        'Ribambelle Сердце Столицы': 'СС | Лок. D',
      }
    }
  },
  computed: {
    getRestaurants () {
      var list = []
      var dates = Object.keys(this.table)
      list = Object.keys(this.table[dates[0]])
      return list
    },
  },
  methods:{
    roundN(val) {
      return Number(val).toFixed(2)
    },
    priceFormat(value) {
      if(!value) return ''
      var formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    },
    numberFormat(value) {
      if(!value) return ''
      var formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    },
    optionSelectDate(option){
      this.date=option;
    },
    loadData() {
      var url = []
      if(this.date && this.date.start) url.push('start='+this.date.start)
      if(this.date && this.date.end) url.push('end='+this.date.end)

      axios.get('https://graph-api.ribambelle.ru/site/plan?'+url.join('&')).then(res=> {
        this.table = res.data
      })
    },
    savePlan() {
      axios.post('https://graph-api.ribambelle.ru/site/save-plan', {
        date: this.table
      }).then(()=>{
      })
    }
  },
  mounted() {
  }
}
</script>


<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
table {
  margin-top: 30px;
  width: calc(100% - 20px);
  margin: 10px;
}
thead th {
  text-align: center;
  border: 1px solid black
}
thead tr {
  background: rgb(153, 130, 87);
  color: white;

}
tbody tr:nth-child(even) {
  background: rgba(153, 130, 87, 0.25);
}
tbody td {
  border: 1px solid black;
  text-align: center;
}
tbody td input {
  width: 100%;
  text-align: center;
  border: none;
  background: transparent;
}
.chartMenu p {
  padding: 10px;
  font-size: 20px;
}
.chartCard {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chartBox {
  width: 700px;
  background: white;
}
.colspan_1{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid: calc(80vh - 370px) auto / 1fr;
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_1_5{
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_3{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_4{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.chart-line-container{
  height: calc(90vh - 450px);
}
button {
  cursor: pointer;
  position: relative;
  min-height: 50px;
  width: 350px;
  background: #998257;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-left: 25px;
  border: none;
}
.parameters-list{
  max-width: 400px;
  margin: 30px auto;
}
.parameters-element{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #222222;
  padding: 10px;
}
.parameters-element__title{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  text-transform: uppercase;
}
.parameters-element__value{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  color: #222222;
}
.dropdown-list{
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  gap:30px;
  flex-wrap: wrap;
}
.indicator-list{
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap:30px;
  flex-wrap:wrap;
  margin-bottom: 30px;
}
.indicator-element{
  width: 100%;
  height: 252px;
  background: rgba(153, 130, 87, 0.25);
  border-radius: 25px;
  padding: 25px;
  justify-self: center;
}
.main-info-check{
}
.main-info-check__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #222222;
  text-align: left;
}
.main-info-check__cost{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 62px;

  text-transform: uppercase;

  color: #A0AB51;
  text-align: left;
}
.main-info-check__stats{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.main-info-check__stats span{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #A0AB51;
}
.deep-check{
  border-top: 2px solid black;
  padding-top: 13px;
  margin-top: 13px;
}
.deep-check__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 21px;
  line-height: 31px;
}
.deep-check__stats{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #222222;
}
.deep-check__stats span{
  color: #A0AB51;
}
.chart-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chart-info__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  color: #998257;
}
.chart-info__description{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #222222;
  text-align: center;
}
.chart-info__description span{
  color: #A0AB51;
}
.chart-line-container{
  margin-left: 30px;
}
</style>